<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_jiyd',
      headers: [
        [{
          label: 'Jenis Instrumen',
          dataKey: 'jenis_instrumen',
        }, {
          label: 'Pengembang dan Validator',
          dataKey: 'pengembang_dan_validator',
        }, {
          label: 'Pelaksanaan Uji Coba Instrumen',
          dataKey: 'pelaksanaan_uji_coba_instrumen',
        }, {
          label: 'Finalisasi Instumen',
          dataKey: 'finalisasi_instumen',
        }]
      ],
      form: {
        inputs: [{
          label: 'Jenis Instrumen',
          name: 'jenis_instrumen',
          dataKey: 'jenis_instrumen',
          type: 'text',
          
          placeholder: 'Angket  Kepuasan Dosen terhadap Pelayanan Manajemen',
        }, {
          label: 'Pengembang dan Validator',
          name: 'pengembang_dan_validator',
          dataKey: 'pengembang_dan_validator',
          type: 'text',
          
          placeholder: 'Penjamin Mutu Universitas '
        }, {
          label: 'Pelaksanaan Uji Coba Instrumen',
          name: 'pelaksanaan_uji_coba_instrumen',
          dataKey: 'pelaksanaan_uji_coba_instrumen',
          type: 'text',
          
          placeholder: 'dilakukan di lingkungan UPPS dan PS'
        }, {
          label: 'Finalisasi Instumen',
          name: 'finalisasi_instumen',
          dataKey: 'finalisasi_instumen',
          type: 'text',
          
          placeholder: 'Adanya Instrumen yang siap di isi oleh Dosen dilingkungkan UPPS dan PS'
        }]
      },
    }
  },
}
</script>
